body {
  font-family: Sans-serif;
  font-size: 18px;
  background:#7a7575;
}
.faqBody{
  padding-top: 80px;
}

.content {
  width: 80%;
  padding: 0;
  margin: 0 auto;
}

.centerplease {
  display: flex;
  justify-content: center;
}

/*Question*/
.question {
  color: white;
  position: relative;
  background: #928d8d;
  margin: 0;
  padding: 10px 10px 10px 50px;
  display: block;
  width:100%;
  cursor: pointer;
}
/*Answer*/
.answers {
  padding: 0px 15px;
  margin: 5px 0;
  width:100%!important;
  height: 0;
  overflow: hidden;
  z-index: -1;
  position: relative;
  opacity: 0;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;
}

.questions:checked ~ .answers{
  height: auto;
  opacity: 1;
  padding: 15px;
  
}

/*FAQ Toggle*/
.plus {
  position: absolute;
  margin-left: 10px;
  z-index: 5;
  font-size: 2em;
  line-height: 100%;
  -webkit-user-select: none;    
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: .3s ease;
  -moz-transition: .3s ease;
  -o-transition: .3s ease;
  transition: .3s ease;

}

.questions:checked ~ .plus {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);

}

.questions {
  display: none;
  
}



