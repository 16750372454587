/************ Navbar Css ************/
/* dropdown menu */

#dropdown-basic {
    background: none;
    border-style: none;
    padding-top: -30px;
  }
  
  #dropdown-basic:hover {
    display: block;
  }
  
  #dropdown-banner {
    display: block;
    background: rgba(128, 125, 130, 0.19);
    border-radius: 10px;
    border-style: none;
    margin-top: 10px;
    margin-left: 30px;
    /* padding-top: -30px; */
  }
  
  #dropdown-basic:focus {
    outline: none;
    box-shadow: none;
  
  }
  
  
  #dropdown-basic:hover {
    background: rgba(0, 0, 0, 0.15);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(1.7px);
    -webkit-backdrop-filter: blur(1.7px);
  }
  
  #dropMenu {
    background: rgba(11, 51, 255, 0.15);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
  
  #itemColor {
    color: white;
  
  }
  
  #itemColor:hover {
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  }
  
  nav.navbar {
    background: rgba(0, 0, 0, 0.37);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    height: auto;
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition: 0.32s ease-in-out;
  }
  
  nav.navbar.scrolled {
    padding: 10px 0;
    background-color: #121212;
  }
  
  nav.navbar a.navbar-brand {
    width: 9%;
  }
  
  nav.navbar .navbar-nav .nav-link.navbar-link {
    font-weight: 300;
    color: rgb(255, 253, 253) !important;
    letter-spacing: .8px;
    /* padding: 0px; */
    font-size: 15px;
    /* opacity: 0.75; */
  }
  
  nav.navbar .navbar-nav .nav-link.navbar-link:hover {
    background: rgba(159, 5, 255, 0.13);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
  }
  
  nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
  nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
  }
  
  span.navbar-text {
    display: flex;
    align-items: center;
  }
  
  .social-icon {
    display: inline-block;
    margin-left: 14px;
  }
  
  .social-icon a {
    width: 42px;
    height: 42px;
    background: rgba(217, 217, 217, 0.1);
    display: inline-flex;
    border-radius: 50%;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    line-height: 1;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  
  .social-icon a::before {
    content: "";
    width: 42px;
    height: 42px;
    position: absolute;
    background-color: #ffffff;
    border-radius: 50%;
    transform: scale(0);
    transition: 0.3s ease-in-out;
  }
  
  .social-icon a:hover::before {
    transform: scale(1);
  }
  
  .social-icon a img {
    width: 40%;
    z-index: 1;
    transition: 0.3s ease-in-out;
  }
  
  .social-icon a:hover img {
    filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
  }
  
  .navbar-text button {
    font-weight: 700;
    color: #fff;
    border: 1px solid #fff;
    padding: 18px 34px;
    font-size: 18px;
    margin-left: 18px;
    position: relative;
    background-color: transparent;
    transition: 0.3s ease-in-out;
  }
  
  .navbar-text button span {
    z-index: 1;
  }
  
  .navbar-text button::before {
    content: "";
    width: 0%;
    height: 100%;
    position: absolute;
    background: rgba(146, 4, 240, 0.37);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.7px);
    -webkit-backdrop-filter: blur(2.7px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    left: 0;
    top: 0;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }
  
  .navbar-text button:hover {
    color: #6c696c;
  }
  
  .navbar-text button:hover::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  nav.navbar .navbar-toggler:active,
  nav.navbar .navbar-toggler:focus {
    outline: none;
  }
  
  nav.navbar .navbar-toggler-icon {
    width: 24px;
    height: 17px;
    background-image: none;
    position: relative;
    border-bottom: 2px solid #fff;
    transition: all 300ms linear;
    top: -2px;
  }
  
  nav.navbar .navbar-toggler-icon:focus {
    border-bottom: 2px solid #fff;
  }
  
  nav.navbar .navbar-toggler-icon:after,
  nav.navbar .navbar-toggler-icon:before {
    width: 24px;
    position: absolute;
    height: 2px;
    background-color: #fff;
    top: 0;
    left: 0;
    content: '';
    z-index: 2;
    transition: all 300ms linear;
  }
  
  nav.navbar .navbar-toggler-icon:after {
    top: 8px;
  }
  
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
    transform: rotate(45deg);
    background-color: #fff;
    height: 2px;
  }
  
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
    transform: translateY(8px) rotate(-45deg);
    background-color: #fff;
    height: 2px;
  }
  
  nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    border-color: transparent;
  }
  
  /************ Banner Css ************/
  .banner {
    margin-top: 0;
    padding: 260px 0 100px 0;
    background-image: url('../../assets/img/banner-bg.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  
  .banner .tagline {
    font-weight: 700;
    letter-spacing: 0.8px;
    padding: 8px 10px;
    background: linear-gradient(90.21deg, rgba(170, 54, 124, 0.5) -5.91%, rgba(74, 47, 189, 0.5) 111.58%);
    border: 1px solid rgba(255, 255, 255, 0.5);
    font-size: 20px;
    margin-bottom: 16px;
    display: inline-block;
  }
  
  .banner h1 {
    font-size: 65px;
    font-weight: 700;
    letter-spacing: 0.8px;
    line-height: 1;
    margin-bottom: 20px;
    display: inline;
  }
  
  .banner p {
    padding-top: 20px;
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    width: 96%;
  }
  
  .banner button {
    color: #fff;
    font-weight: 700;
    font-size: 20px;
    margin-top: 30px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
  }
  
  .banner button svg {
    font-size: 25px;
    margin-left: 10px;
    transition: 0.3s ease-in-out;
    line-height: 1;
  }
  
  .banner button:hover svg {
    margin-left: 25px;
  }
  
  .banner img {
    animation: updown 3s linear infinite;
  }
  
  @keyframes updown {
    0% {
      transform: translateY(-20px);
    }
  
    50% {
      transform: translateY(20px);
    }
  
    100% {
      transform: translateY(-20px);
    }
  }
  
  .txt-rotate>.wrap {
    border-right: 0.08em solid #666;
  }
  