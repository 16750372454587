/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.appLoader {
  text-align: center;
  background: rgb(78, 15, 70);
  background: linear-gradient(90deg, rgba(78, 15, 70, 1) 0%, rgba(131, 55, 148, 1) 48%, rgba(78, 15, 70, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    width: 100%;
    box-sizing: border-box;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}






/************ Footer Css ************/

ul {
  margin: 0px;
  padding: 0px;
}

.footer-section {
  background: #151414;
  position: relative;
  padding: 0px;
}

.footer-cta {
  border-bottom: 1px solid #373636;
}

.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}

.cta-text {
  padding-left: 15px;
  display: inline-block;
}

.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}

.cta-text span {
  color: #757575;
  font-size: 15px;
}

.footer-content {
  position: relative;
  z-index: 2;
}

.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}

.footer-logo {
  margin-bottom: 30px;
}

.footer-logo img {
  max-width: 200px;
}

.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}

.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 20px;
}

.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}

.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}

.facebook-bg {
  background: #3B5998;
}

.twitter-bg {
  background: #55ACEE;
}

.google-bg {
  background: #DD4B39;
}

.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 40px;
  position: relative;
}

.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: #ff5e14;
}

.footer-widget ul li {
  display: inline-block;
  /* float: left; */
  width: 50%;
  margin-bottom: 12px;
}

.footer-widget ul li a:hover {
  color: #ff5e14;
}

.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}

.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2E2E2E;
  border: 1px solid #2E2E2E;
  color: #fff;
}



.copyright-area {
  background: #202020;
  padding: 25px 0;
}

.copyright-text p {
  margin: 0;
  /* font-size: 18px; */
  color: #878787;
  padding-left: 55px;
}

.copyright-text p a {
  color: #ff5e14;
}

.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}

.footer-menu li:hover a {
  color: #ff5e14;
}

.footer-menu li a {
  font-size: 14px;
  color: #878787;
}

/************ Footer Css ************/

/************ Projects Css ************/
.contact {
  background: linear-gradient(90.21deg, #352972 -5.91%, #040115 111.58%);
  padding: 60px 0 200px 0;
}

/* .contact img {
  width: 92%;
} */

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}





/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.newsletter-bx {
  background: #FFFFFF;
  border-radius: 55px;
  color: #121212;
  padding: 100%;
  width: 100%;
  margin-bottom: 80px;
  margin-top: -122px;
}

.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}

.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}

.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}

.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}

.new-email-bx button {
  background: linear-gradient(90.21deg, #AA367C -5.91%, #4A2FBD 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}

.footer img {
  width: 100%;
}

.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}




/* Pop up boxes */

.Portfolio {
  position: relative;
}

.Portfolio .box {
  border-radius: 10px;
  position: relative;
}

.Portfolio .box:hover {
  background: none;
  transform: translateY(-0);
}

.Portfolio .img {
  height: 300px;
  overflow: hidden;
  transition: 0.5s;
}

.Portfolio img {
  width: 100%;
  border-radius: 10px;
  transition: 0.5s;
}

.Portfolio .box .img:hover img {
  transform: scale(1.2);
}

.Portfolio .category {
  margin: 20px 0 30px 0;
  text-transform: uppercase;
}

.Portfolio .category span {
  color: #ff014f;
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: 500;
}

.Portfolio .arrow {
  position: absolute;
  right: 30px;
  bottom: 11%;
  transform: rotate(-45deg);
  color: #ff014f;
  opacity: 0;
  transition: 0.5s;
}

.Portfolio .title:hover .arrow {
  opacity: 1;
}

.Portfolio h2 {
  font-size: 18px;
  transition: 0.5s;
  padding-bottom: 30px;
}

.Portfolio .box:hover h2 {
  color: #ff014f;
}

.Portfolio .box:hover {
  color: black;
}

/*------------popup--------*/
s .btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 9999;
}

.overlay {
  background-color: #ecf0f3;
}

.modal-content {
  position: absolute;
  top: 12%;
  left: 8%;
  line-height: 1.4;
  background: #f0f3f5;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 85%;
  min-width: 60%;
  z-index: 9999;
  box-shadow: 4px 4px 8px #cbcbcb, -4px -4px 8px #ffffff;
  padding: 50px;
  border-radius: 10px;
}

.modal-text {
  padding: 0 100px 0 50px;
}

.modal-text h1 {
  margin: 20px 0 20px 0;
  font-size: 30px;
}

.modal-text p {
  font-size: 17px;
  margin-bottom: 20px;
  line-height: 25px;
}

.modal-text button {
  margin-right: 20px;
  color: #ff014f;
}

.modal-text button i {
  margin-left: 10px;
}

.modal-text button.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  padding: 0 0;
  font-size: 20px;
}

.modal-text button.close-modal i {
  margin: 0;
}

/*------------popup--------*/
@media (max-width: 768px) {

  .modal,
  .overlay {
    overflow: scroll;
  }

  .modal .modal-img {
    margin: 50px 0 50px 0;
  }

  .modal-content {
    flex-direction: column;
  }

  .modal .right,
  .modal .left {
    padding: 0;
  }
}




/* Services tab */


.formMulti {
  background-color: white;
  padding-top: 10px;
}

.formHeader {
  padding-bottom: 30px;
  color: black;
  display: flex;
  justify-content: center;
}

.ourServices {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1500px;
  padding: 50px 300px 10px 300px;

}

.mousePoint {
  background: rgba(0, 0, 0, 0.27);
  border-radius: 16px;
}

.d_flex {
  display: flex;
  justify-content: space-between;
}

.f_flex {
  display: flex;
}




.btn_shadow {
  padding: 18px 25px;
  border-radius: 6px;
  transition: 0.3s all ease;
  cursor: pointer;
  color: #000;
  transition: 0.5s;
}

.btn_shadow {
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
  box-shadow: 4px 4px 8px #cbcbcb, -4px -4px 8px #ffffff;
}

.btn_shadow:hover {
  background: #ff014f;
  color: white;
  transform: translateY(-10px);
}

.box_shodow {
  background: linear-gradient(145deg, #e2e8ec, #ffffff);
  box-shadow: 4px 4px 8px #cbcbcb, -4px -4px 8px #ffffff;
  border-radius: 10px;
}




.contactIcons{
  display: block;
  text-align: center;
}


