/************ Skills Css ************/




.containerSkills {
    margin-left: 20px;
  }
  
  
  
  .skill-bx {
    /* display: inline-block; */
    /* display: flex; */
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    border: solid;
    border-color: rgba(185, 184, 194, 0.47);
    background: linear-gradient(90deg, rgba(34, 32, 35, 0.47) 0%, rgba(23, 23, 63, 0.47) 35%, rgba(9, 57, 151, 0.47) 100%);
    width: 97%;
    border-radius: 30px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    position: relative;
    left: 5px;
    padding: 60px 100px 0 75px;
  
  }
  .skill-faq {
    /* display: inline-block; */
    /* display: flex; */
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    border: solid;
    border-color: rgba(185, 184, 194, 0.47);
    background: linear-gradient(90deg, rgba(34, 32, 35, 0.47) 0%, rgba(23, 23, 63, 0.47) 35%, rgba(9, 57, 151, 0.47) 100%);
    width: 97%;
    border-radius: 30px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    position: relative;
    left: 5px;
    padding: 30px 100px 0 75px;
  
  }
  
  .imageText {
    position: relative;
    left: 45%;
    bottom: 65%;
    color: rgb(226, 214, 214);
  }
  
  .faqPic{
    padding-top: 90px;
  }
  
  .careerFlex{
    display: flex;
    gap: 1rem;
    width: 900px;
    flex-direction: row;
  }
  
  
  
 .paddingBannerTwo {
    text-align: left;
}

.email-row {
  max-width: 100%; /* Prevents the row from exceeding screen width */
  overflow-x: hidden;
    display: flex;
    flex-direction: column; /* Stacks items vertically */
    text-align: left; /* Aligns text left */
}




/* Media queries for mobile */
@media (max-width: 768px) {
    .email-row {
      padding-bottom: 100px;
    }
    #emailCenter{
      padding-left: 25px;
    }
    #emails{
      text-align: center;
    }
    .containerSkills {
      margin-left: 0px;
    }

    
}
  
     
   
   
  
  
  
  .headerContact {
    color: #040115;
  }
  
  .viewServices {
    background: rgba(63, 40, 40, 0.47);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    padding-bottom: 10px;
    position: relative;
    bottom: 20px;
  
    margin-bottom: 10px;
  
  
  }
  
  .skill h2 {
    font-size: 45px;
    font-weight: 700;
  }
  
  .skill p {
    color: #B8B8B8;
    font-size: 18px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 37px;
  }
  
  .skill-slider {
    width: 80%;
    margin: 0 auto;
    position: relative;
  }
  
  .skill-slider .item img {
    width: 50%;
    margin: 0 auto 15px auto;
  }
  
  .background-image-left {
    top: 28%;
    position: absolute;
    bottom: 0;
    width: 40%;
    z-index: -4;
  }
  