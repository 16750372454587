.counter {
  font-size: 2rem;
  font-weight: 600;
  color: #ccd9d4;
}

.counter__title {
  font-size: 1rem;
  color: #b6b7b7;
  font-weight: 500;
}

.about__counter{

  padding-top: 5px;
}

.top_count{
  display: flex;
  gap: 5rem;
}

.bottom_count{
  display: flex;
  gap: 7rem;

}

.about__content {
  
  padding-left: 50px;
}

.about__img img {
  padding-top: 5px;
  border-radius: 15px;
}

.about__content h2 {
  font-weight: 600;
}

.aboutUsCont{
  padding-top:150px;
}
